import { HttpClient, HttpParams } from '@angular/common/http';

import { API } from '../shared/app-constant';
import { GenericResponse } from '../models/shared/generic-response.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { select, Store } from "@ngrx/store";
import { AppState } from "../state/app-state/app-reducer";
import { selectLoggedInUser, selectRoleName } from "../state/auth-state/auth.selectors";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  refreshCount: boolean = false;

  isUserLoggedIn: boolean = false;

  userLoginDetails$: Subject<any> = new BehaviorSubject<any>(null);
  userRoleDetails$: Subject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.pipe(select(selectLoggedInUser(this.store))).subscribe({
      next: loggedInUser => this.isUserLoggedIn = !!loggedInUser
    });
  }

  getLoggedInUserAPI(): Observable<any> {
    if (this.isUserLoggedIn) return this.store.pipe(select(selectLoggedInUser(this.store)));
    else {
      return this.http.post<any>(
        `${environment.baseURL}${API.GET_LOGGED_IN_USER}`,
        {}
      );
    }
  }

  getSuperUserData(entityId: number): Observable<any> {
    return this.http.post<any>(`${environment.baseURL}${API.GET_SUPER_EMPLOYEE}`, {}, { params: { entityId } })
  }

  getLoggedInUserRoleName = (): Observable<any> => this.store.pipe(select(selectRoleName));

  getLoggedInUserId(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.wlcBaseURL}${API.GET_LOGGED_IN_USER_ID}/${id}`,
      {}
    );
  }

  submitForApproval(userId: number): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(
      `${environment.baseURLMis}${API.SUBMIT_FOR_APPROVAL}/${userId}`
    );
  }

  assignRole(body: any): Observable<any> {
    return this.http.put<any>(
      `${environment.baseURLMis}${API.ASSIGN_ROLE}`,
      body
    );
  }

  logout(): Observable<any> {
    return this.http.post<any>(`${environment.baseURLWLC}${API.USER_LOGOUT}`, {});
  }

  getAadharSignature(
    token: string,
    email: string,
    username: string
  ): Observable<any> {
    const params = new HttpParams()
      .append('token', token)
      .append('email', email)
      .append('user_name', username);

    return this.http.get<any>(
      `${environment.baseURLAadhar}${API.AADHAR_SIGNATURE}`,
      { params }
    );
  }

  getAadharSignaturekyc(
    token: string,
    email: string,
    username: string
  ): Observable<any> {
    const params = new HttpParams()
      .append('token', token)
      .append('email', email)
      .append('user_name', username);

    return this.http.get<any>(
      `${environment.baseURLAadhar}${API.AADHAR_SIGNATURE_KYC}`,
      { params }
    );
  }

  changePassword(body: any): Observable<any> {
    return this.http.post<any>(
      `${environment.baseURL}${API.CHANGE_PASSWORD}`,
      body
    );
  }

  changeEmail(currentEmail: string, newEmail: string): Observable<any> {
    const params = new HttpParams()
      .append('currentEmail', currentEmail)
      .append('newEmail', newEmail);
    return this.http.post<any>(
      `${environment.baseURL}${API.CHANGE_EMAIL}`,
      '',
      { params }
    );
  }

  updateDesignation(currentDesignation: string, newDesignation: string): Observable<any> {
    const params = new HttpParams()
      .append('currentDesignationName', currentDesignation)
      .append('newDesignationName', newDesignation);
    return this.http.post<any>(
      `${environment.baseURL}${API.UPDATE_DESIGNATION}`,
      '',
      { params }
    );
  }

  saveDesignation(body: any): Observable<any> {
    return this.http.post<any>(
      `${environment.baseURL}${API.SAVE_DESIGNATION}`,
      body
    );
  }

  getDesignation(): Observable<any> {
    return this.http.post<any>(
      `${environment.baseURL}${API.GET_DESIGNATION}`,
      ''
    );
  }

  verifyToken = (token: string) => this.http.get<GenericResponse>(environment.baseURL + API.VERIFY_TOKEN, {
    params: { token }
  })

  resendEmail = (email: string) => this.http.post<GenericResponse>(environment.baseURL + API.RESEND_EMAIL, {}, {
    params: { email }
  })
}
